<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
    scrollable
    persistent
  >
    <add-message-contents
      v-if="showMessageForm"
      @message-added="showServiceProviders"
      @sending-cancelled="closeModal"
    />
    <select-service-providers
      v-if="showServiceProvidersList"
      :message-contents="messageContents"
      @message-send="messageSend"
      @sending-cancelled="closeModal"
    />
  </v-dialog>
</template>
<script>
import AddMessageContents from './AddMessageContents.vue';
import SelectServiceProviders from './SelectServiceProviders.vue';

export default {
    name: 'SendEmergencyMsgLanding',
    components: {
        'add-message-contents': AddMessageContents,
        'select-service-providers': SelectServiceProviders,
    },
    data () {
      return {
        showModal: true,
        showMessageForm: true,
        showServiceProvidersList: false,
        messageContents: {},
      };
    },
     watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      showServiceProviders (val) {
        this.messageContents = val;
        this.showMessageForm = false;
        this.showServiceProvidersList = true;
      },
      closeModal () {
        this.showMessageForm = false;
        this.showServiceProvidersList = false;
        this.showModal = false;
      },
      messageSend () {
        this.showMessageForm = false;
        this.showServiceProvidersList = false;
        this.showModal = false;
        this.$emit('get-alert-histories');
      },
    },
};
</script>
