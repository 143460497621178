<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-question-outline
      </v-icon> Suspicious Check-Ins
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              fab
              dark
              outlined
              color="#37474F"
              small
              class="btn-style"
              v-on="on"
              @click="getAllSuspiciousCheckIns()"
            >
              <v-icon
                small
                dark
              >
                mdi-file-multiple-outline
              </v-icon>
            </v-btn>
          </template>
          <span>ALL Records</span>
        </v-tooltip>
        <v-btn
          color="#FF3700"
          @click="showFilterForm = true"
        >
          Filter
        </v-btn>
      </v-col>
      <hr>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="suspiciousCheckIns"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.service_provider.first_name }} {{ item.service_provider.last_name }}</td>
            <td>{{ item.service_provider.company.company_name }}</td>
            <td>
              <font v-if="item.site_id !== null">
                {{ item.site_id.name }}
              </font><font v-else>
                N/A
              </font>
            </td>
            <td>
              <v-chip
                outlined
                class="type"
                color="#37474F"
                dark
              >
                {{ item.checkin_type }}
              </v-chip>
            </td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>{{ item.reason }}</td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <filter-suspicious-check-ins
      v-if="showFilterForm"
      @closed="showFilterForm = false"
    />
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import FilterSuspiciousCheckIns from './FilterSuspiciousCheckIns.vue';

  export default {
    name: 'SuspiciousCheckIns',
    components: {
      'centre-spinner': spinner,
      'filter-suspicious-check-ins': FilterSuspiciousCheckIns,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    data () {
      return {
        search: '',
        loading: false,
        headers: [
          { text: 'Name', align: 'start', value: 'service_provider.first_name' },
          { text: 'Company Name', value: 'company_name' },
          { text: 'Location', value: 'lcation' },
          { text: 'Type', value: 'type' },
          { text: 'Reported At', value: 'created_at' },
          { text: 'Alert', value: 'reason' },
        ],
        showFilterForm: false,
      };
    },
    computed: {
      suspiciousCheckIns () {
        const list = this.$store.getters['widgets/getSuspiciousCheckIns'];
        const sortedList = list.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return sortedList;
      },
    },
    async mounted () {
      await this.getAllSuspiciousCheckIns();
    },
    methods: {
      async getAllSuspiciousCheckIns () {
        this.loading = true;
        await this.$store.dispatch('widgets/fetchSuspiciousCheckIns');
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
  margin-left: 20px;
}
.type {
  text-transform: capitalize;
}
.btn-style {
  margin-right: 15px;
}
</style>
