import { walkTreeData } from 'he-tree-vue';

export function getDefaultStructure (serviceProviders) {
    const defaultStructure = [{ text: 'All Service Providers', children: [] }];
    const allServiceProviders = serviceProviders;
    const structure = defaultStructure;
    for (const serviceProvider of allServiceProviders) {
        structure[0].children.push({
            id: serviceProvider.external_id,
            text: `${serviceProvider.first_name} ${serviceProvider.last_name} - ${serviceProvider.company.company_name}`,
        });
    }
    return structure;
};

export function addMissingServiceProviders (treeData, serviceProviders) {
    const availableServiceProviders = [];
    walkTreeData(treeData, (node) => {
        if (node.id !== undefined) {
            availableServiceProviders.push(node.id);
        }
    });
    const newServiceProviders = serviceProviders.filter(i => !availableServiceProviders.includes(i.external_id));
    for (const newServiceProvider of newServiceProviders) {
        treeData[0].children.push({
            id: newServiceProvider.external_id,
            text: `${newServiceProvider.first_name} ${newServiceProvider.last_name} - ${newServiceProvider.company.company_name}`,
        });
    }
    return treeData;
};
